<template>
  <div class="flex flex-col">
    <h1 class="xl:title-48-bold-700 title-32-bold-700">
      <slot name="title"></slot>
    </h1>
    <p
      class="xl:mt-4 mt-6 text-v-ui-text-monochrome-55 xl:title-20-medium-500 title-16-medium-500"
    >
      <slot name="description"></slot>
    </p>
    <div
      class="flex flex-col items-start mt-8 xl:flex-row xl:flex-wrap xl:gap-x-3 xl:gap-y-4 gap-y-2"
    >
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexPageTitle'
};
</script>

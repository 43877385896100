<template>
  <p
    class="rounded-t-2xl pb-6 text-v-ui-root-monochrome-0 title-32-bold-700 px-3 pt-3 pb-5"
    :class="color"
  >
    {{ title }}
  </p>
</template>

<script>
export default {
  name: 'MobileSlideTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  }
};
</script>

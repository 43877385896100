<template>
  <div class="relative xl:w-[22rem] w-[280px] xl:h-[37.5rem]">
    <div v-if="isMobileDevice">
      <nuxt-img
        format="webp"
        class="xl:w-full xl:h-full"
        alt="map"
        width="280"
        height="445"
        :src="mapImage"
      />
      <div
        class="absolute top-3 right-3 left-3 bg-v-ui-root-monochrome-0 p-3 rounded-lg xl:hidden"
      >
        <p class="text-v-ui-text-monochrome title-32-bold-700">
          {{ slide.title }}
        </p>
      </div>
    </div>
    <div v-else class="v-analysis-map h-full">
      <client-only>
        <yandex-map
          ymap-class="h-full"
          :settings="settings"
          :coords="coords"
          :controls="[]"
          zoom="13"
          :options="mapOptions"
        >
          <ymap-marker
            v-for="marker in markers"
            :key="marker.coords.toString()"
            :coords="marker.coords"
            :marker-id="marker.coords.toString()"
            :balloon-template="
              marker.type === 'helix' ? helixBalloonTemplate : mdBalloonTemplate
            "
            :icon="marker.type === 'helix' ? helixMarkerIcon : mdMarkerIcon"
          >
          </ymap-marker>
        </yandex-map>
      </client-only>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalysisMapSlide',
  components: {
    yandexMap: () =>
      import('vue-yandex-maps').then(({ yandexMap }) => yandexMap),
    ymapMarker: () =>
      import('vue-yandex-maps').then(({ ymapMarker }) => ymapMarker)
  },
  props: {
    slide: {
      type: Object,
      required: true
    },
    isMobileDevice: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      mapImage: '/images/pages/index/analysis-map.webp',

      coords: [55.749299, 37.57059],
      mapOptions: {
        restrictMapArea: [
          [55.893330236641766, 37.32204173070562],
          [55.60735000663139, 37.85112685945855]
        ]
      },
      markers: [
        { type: 'md', coords: [55.882701, 37.648321] },
        { type: 'md', coords: [55.871602, 37.690111] },
        { type: 'helix', coords: [55.749299, 37.57059] },
        { type: 'helix', coords: [55.737238, 37.588072] },
        { type: 'helix', coords: [55.820642, 37.642042] },
        { type: 'helix', coords: [55.729123, 37.570474] },
        { type: 'helix', coords: [55.78646, 37.560484] },
        { type: 'helix', coords: [55.756903, 37.409002] },
        { type: 'helix', coords: [55.669706, 37.771041] },
        { type: 'helix', coords: [55.793915, 37.615479] },
        { type: 'helix', coords: [55.711605, 37.66459] },
        { type: 'helix', coords: [55.870218, 37.665974] }
      ],
      settings: {
        apiKey: this.$config.APP_YANDEX_MAP_API_KEY,
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
      }
    };
  },
  computed: {
    helixBalloonTemplate() {
      return `
        <img loading="lazy" alt="balloon" src="${this.helixBalloonImage}">`;
    },
    gemotestBalloonTemplate() {
      return `
        <img loading="lazy" alt="balloon" src="${this.gemotestBalloonImage}">`;
    },
    mdBalloonTemplate() {
      return `
        <img loading="lazy" alt="balloon" src="${this.mdBalloonImage}">`;
    },
    helixBalloonImage() {
      return this.$img('/images/pages/index/analysis-map-helix-balloon.webp', {
        format: 'webp'
      });
    },
    gemotestBalloonImage() {
      return this.$img(
        '/images/pages/index/analysis-map-gemotest-balloon.webp',
        {
          format: 'webp'
        }
      );
    },
    mdBalloonImage() {
      return this.$img('/images/pages/index/analysis-map-md-balloon.webp', {
        format: 'webp'
      });
    },
    helixMarkerIcon() {
      return {
        layout: 'default#imageWithContent',
        imageHref: this.$img(
          '/images/pages/index/analysis-map-helix-pin.webp',
          { format: 'webp' }
        ),
        imageSize: [40, 48],
        imageOffset: [0, 0],
        contentOffset: [0, 15]
      };
    },
    mdMarkerIcon() {
      return {
        layout: 'default#imageWithContent',
        imageHref: this.$img('/images/pages/index/analysis-map-md-pin.webp', {
          format: 'webp'
        }),
        imageSize: [40, 48],
        imageOffset: [0, 0],
        contentOffset: [0, 15]
      };
    },
    gemotestMarkerIcon() {
      return {
        layout: 'default#imageWithContent',
        imageHref: this.$img(
          '/images/pages/index/analysis-map-gemotest-pin.webp',
          { format: 'webp' }
        ),
        imageSize: [40, 48],
        imageOffset: [0, 0],
        contentOffset: [0, 15]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.v-analysis-map {
  ::v-deep(.ymap-container) {
    height: 100%;

    .ymaps-2-1-79-balloon {
      box-shadow: none;
      padding: 0;

      .ymaps-2-1-79-balloon__layout {
        background: none;
      }

      .ymaps-2-1-79-balloon__content {
        background: none;
        padding: 0;
        margin: 0;
      }
      .ymaps-2-1-79-balloon__close {
        display: none;
      }

      .ymaps-2-1-79-balloon__tail {
        display: none;
      }
    }

    .ymaps-2-1-79-copyrights-pane {
      display: none;
    }

    .ymaps-2-1-79-placemark-overlay {
      height: 48px !important;
      width: 40px !important;
    }

    .ymaps-2-1-79-image-with-content-content {
      height: 48px !important;
      width: 40px !important;
    }

    .ymaps-2-1-79-inner-panes {
      border-radius: 1rem;
    }

    .ymaps-2-1-79-map {
      border-radius: 1rem;
    }
  }
}
</style>

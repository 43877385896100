<template>
  <index-page-title>
    <template #title>
      <i18n
        path="domains.domains.pages.analysis.title"
        tag="span"
        class="text-v-ui-root-monochrome-0 xl:title-48-bold-700 title-32-bold-700"
      >
        <br class="xl:hidden" />
        <br />
      </i18n>
    </template>

    <template #description>
      <i18n
        path="domains.domains.pages.analysis.description"
        tag="span"
        class="text-v-ui-root-monochrome-5"
      >
        <br class="xl:hidden" />
        <br class="hidden xl:inline" />
      </i18n>
    </template>

    <template #buttons>
      <a
        v-for="(label, idx) in labels"
        :key="idx"
        class="flex h-9 px-4 items-center justify-center bg-v-ui-root-main-alt rounded-full"
        :class="{
          'pointer-events-none': isAnalysisLinkDisabled
        }"
        :href="label.link"
      >
        <span class="w-3 h-3 mr-2 bg-v-ui-root-main rounded-full"></span>
        <span class="title-16-medium-500 text-v-ui-root-monochrome-0">
          <span> {{ label.title }}</span>
          <span>{{ formatToCurrency(label.price) }}</span>
        </span>
      </a>

      <nuxt-link
        class="flex order-last justify-center items-center h-9 px-4 bg-[#FF2069] rounded-full xl:mt-0 mt-6"
        to="/analysis"
      >
        <span class="text-v-ui-root-monochrome-0 xl:button-m button-s">
          {{ $t('domains.domains.pages.analysis.all-analysis') }}
        </span>
      </nuxt-link>
    </template>
  </index-page-title>
</template>

<script>
import IndexPageTitle from '../index-page-title/IndexPageTitle';
import { сurrencyFormat } from '@/helpers';
export default {
  name: 'AnalysisTitle',
  components: { IndexPageTitle },
  computed: {
    isAnalysisLinkDisabled() {
      return this.$config.APP_INTERNATIONAL_STAND;
    },
    isEnStand() {
      return this.$config.APP_INTERNATIONAL_STAND;
    },
    labels() {
      return [
        {
          title: this.$t('domains.domains.pages.analysis.blood-analysis'),
          price: this.isEnStand ? '7' : '360',
          link:
            'https://www.doctis.ru/analysis/service/obshchiy-analiz-krovi-bez-leykotsitarnoy-formuly-i-soe'
        },
        {
          title: this.$t('domains.domains.pages.analysis.ferrite'),
          price: this.isEnStand ? '9' : '604',
          link: 'https://www.doctis.ru/analysis/service/ferritin'
        },
        {
          title: this.$t('domains.domains.pages.analysis.ttg'),
          price: this.isEnStand ? '6' : '610',
          link: 'https://www.doctis.ru/analysis/service/tireotropnyy-gormon-ttg'
        },
        {
          title: this.$t('domains.domains.pages.analysis.vitamin-d'),
          price: this.isEnStand ? '24' : '1936',
          link: 'https://www.doctis.ru/analysis/service/vitamin-d-25on'
        }
      ];
    }
  },
  methods: {
    formatToCurrency(price) {
      return сurrencyFormat(price);
    }
  }
};
</script>

<style scoped></style>

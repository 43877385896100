<template>
  <div
    ref="slide"
    class="relative bg-v-ui-root-monochrome-0 bottom-3 xl:bg-v-ui-root-monochrome-5 rounded-2xl xl:h-[37.5rem] xl:w-[22rem] xl:bottom-0 xl:pt-[88px] xl:pl-6"
  >
    <p class="hidden text-v-ui-text-monochrome-55 title-16-medium-500 xl:block">
      {{ $t('domains.domains.pages.main.today') }}
    </p>
    <div
      ref="scrollContainer"
      class="custom-scrollbar grid xl:mt-[18px] mt-0 xl:gap-y-4 gap-y-3 px-3 py-5 overflow-scroll h-[23.5rem] xl:h-[37.5rem] xl:w-[21.5rem] xl:pt-0 xl:pl-0 xl:pb-28 xl:pr-8"
    >
      <div
        v-for="(item, idx) in slide.items"
        :key="idx"
        class="relative bg-v-ui-root-monochrome-5 py-5 px-2 flex flex-col rounded-2xl xl:bg-v-ui-root-monochrome-0 xl:p-4"
      >
        <div
          class="absolute flex items-center px-3 h-7 rounded bg-v-ui-root-monochrome-0 right-4 top-4 text-v-ui-text-monochrome title-14-medium-500 xl:bg-v-ui-root-monochrome-5"
        >
          <span>{{ item.time }}</span>
        </div>
        <p class="title-16-medium-500 text-v-ui-text-monochrome">
          {{ item.specialization }}
        </p>
        <p class="note-12-reg-400 text-v-ui-text-monochrome-55 mt-0.5">
          {{ item.description }}
        </p>
        <div class="mt-6 flex items-center">
          <img
            class="rounded-full mr-4 h-10 w-10"
            :src="item.doctor.avatar"
            alt="doctor-avatar"
          />
          <p
            class="title-16-medium-500 text-v-ui-root-main xl:text-base xl:leading-none"
          >
            {{ item.doctor.lastName }} <br />
            {{ item.doctor.firstName }} {{ item.doctor.middleName ?? '' }}
          </p>
        </div>
        <button
          class="flex items-center justify-center xl:h-10 h-8 mt-6 xl:rounded-lg rounded w-full xl:button-m button-s whitespace-nowrap"
          disabled
          :class="
            item.active
              ? 'bg-v-ui-root-main text-v-ui-root-monochrome-0'
              : 'bg-[#D0D1D3] text-v-ui-root-monochrome-0 xl:bg-v-ui-root-monochrome-5 xl:text-v-ui-text-monochrome-20'
          "
        >
          {{
            item.active
              ? $t('domains.domains.pages.main.to-consultation')
              : $t('domains.domains.pages.main.consultation-not-started')
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConsultationSlide',
  props: {
    slide: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      observer: null
    };
  },
  mounted() {
    if (window.IntersectionObserver) {
      this.observer = new IntersectionObserver((e) => {
        if (e[0].isIntersecting && this.$refs.scrollContainer) {
          this.$refs.scrollContainer.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth'
          });
        }
      });
      this.observer.observe(this.$refs.slide);
    }
  },
  beforeDestroy() {
    this.observer?.disconnect();
    this.observer = null;
  }
};
</script>

<style lang="scss" scoped>
// TODO switch to tailwind-scrollbar library when it will be compatible with tailwind v.3

.custom-scrollbar {
  @media (min-width: 1140px) {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #a05aff;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #ecdeff;
      border-radius: 4px;
    }
  }
}
</style>

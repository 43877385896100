<template>
  <div
    class="relative w-full xl:h-[37.5rem] xl:rounded-[36px] xl:px-20 xl:max-w-7xl xl:container"
  >
    <nuxt-img
      class="object-cover h-full w-full absolute inset-0"
      :class="rounded ? 'xl:rounded-[36px]' : ''"
      :src="bgImage"
      alt="background"
      format="webp"
      quality="100"
      :loading="lazy ? 'lazy' : 'eager'"
    />
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'IndexPageCard',
  props: {
    bgImage: {
      type: String,
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<template>
  <div class="index-page-slider w-full">
    <swiper
      v-if="isMobileDevice"
      ref="swiper"
      :options="swiperOptions"
      class="!overflow-visible"
    >
      <swiper-slide
        v-if="!withoutTitle && isMobileDevice"
        class="!w-[280px] xl:pt-0 pt-8"
      >
        <slot name="title"></slot>
      </swiper-slide>

      <swiper-slide
        v-for="(slide, idx) in slides"
        :key="idx"
        class="index-page-slider__slide !w-[280px] !h-[28rem] rounded-2xl select-none shrink-0"
      >
        <slot name="slide" :slide="slide"> </slot>
      </swiper-slide>
    </swiper>
    <div
      v-else
      class="flex items-center justify-between !w-full"
      :class="{ 'flex-row-reverse': reverse }"
    >
      <div v-if="!withoutTitle">
        <slot name="title"></slot>
      </div>

      <div
        class="relative w-[23rem] h-[37.5rem] rounded-[20px] mr-20 ml-20 shrink-0 index-page-slider__wrap-doctors"
        :class="{ 'index-page-slider__wrap-doctors--shadow': isShadow }"
      >
        <div
          v-if="!withoutButton"
          class="absolute z-10 top-6 left-6 flex gap-3"
        >
          <a
            v-for="(slide, idx) in slides"
            :key="idx"
            class="index-page-slider__btn rounded-full grid items-center h-8 px-4 button-s"
            :class="
              idx === currentSlide
                ? 'text-v-ui-text-monochrome bg-v-ui-root-monochrome-0'
                : 'text-v-ui-root-monochrome-0 bg-[#A05AFF] shadow-lg active'
            "
            href="#"
            @click.prevent="goToSlide(slide, idx)"
            >{{ slide.titleShort }}</a
          >
        </div>

        <client-only v-if="!disabled">
          <swiper
            ref="swiper"
            :options="swiperOptions"
            @slideChange="onSlideChange"
          >
            <swiper-slide
              v-for="(slide, idx) in slides"
              :key="idx"
              class="rounded-2xl bg-none w-[23rem] h-[40rem] select-none"
            >
              <slot name="slide" :slide="slide"> </slot>
            </swiper-slide>
          </swiper>
        </client-only>

        <div v-else>
          <div
            v-for="(slide, idx) in slides"
            :key="idx"
            class="h-[40rem] rounded-2xl bg-none w-[23rem] select-none"
          >
            <slot name="slide" :slide="slide"> </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'IndexPageSlider',
  components: { Swiper, SwiperSlide },
  props: {
    isShadow: {
      type: Boolean,
      required: false
    },
    isMobileDevice: {
      type: Boolean,
      required: true
    },
    slides: {
      type: Array,
      required: true
    },
    reverse: {
      type: Boolean,
      default: false
    },
    withoutTitle: {
      type: Boolean,
      default: false
    },
    withoutButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentSlide: 0,

      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 12,
        slideToClickedSlide: true
      }
    };
  },
  computed: {
    disabled() {
      return !this.isMobileDevice && this.slides.length === 1;
    }
  },
  methods: {
    async goToSlide(slide, index) {
      if (slide.link) {
        await this.$router.push(slide.link);
        return;
      }

      this.$refs.swiper?.swiperInstance.slideTo(index);
    },
    onSlideChange() {
      this.currentSlide = this.$refs.swiper?.swiperInstance.realIndex;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'swiper/css/swiper.min.css';

.index-page-slider {
  &__wrap-doctors {
    &--shadow {
      &::before,
      &::after {
        content: '';
        position: absolute;
      }

      &::before {
        top: 0;
        left: 0;
        height: calc(100% - 40px);
        width: calc(100% - 16px);
        border-radius: 20px;
        box-shadow: -16px -16px 24px 8px rgba(255, 255, 255, 0.8);
      }

      &::after {
        top: 24px;
        right: 16px;
        height: calc(100% - (40px + 24px));
        width: calc(100% - (16px + 24px));
        box-shadow: 4px 16px 16px 2px rgba(91, 58, 239, 0.1);
      }
    }
  }

  &__btn {
    transition: box-shadow 0.3s, background-color 0.3s, color 0.3s;

    &.active {
      box-shadow: 0px 4px 16px 0px rgba(222, 90, 255, 0.48);
    }
  }
}

::v-deep(.swiper-wrapper) {
  display: flex;
}

@media screen and (max-width: 1139px) {
  .index-page-slider {
    &__slide {
      overflow: hidden;
      box-shadow: 0px 4px 12px 1px rgba(21, 26, 37, 0.08);
    }
  }
}
</style>
